import React from 'react'
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import ContactForm from '../components/contact/ContactForm'

import {  
  useStaticQuery, 
  graphql} from 'gatsby'
import Hero from '../components/Hero'

const ContactPage = () => {

  const data = useStaticQuery(graphql`
  query contactQuery {
    strapiContact {
      Titulo
      HeroBanner {
        childImageSharp {
          fixed(width:1290) {
            src
          }
        }
      }
    }
  }
`)
  return(
    <Layout>
      <SEO title="Contact" />
      <Hero data = {data} />
      <ContactForm/>
    </Layout>
  )
}

export default ContactPage
