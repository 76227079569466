import React from 'react'
import { 
            FormHelperText,
            FormGroup, 
            Button, 
            Container, 
            TextField,
            CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
//import emailjs from 'emailjs-com'
import { Link } from 'gatsby'
import axios from 'axios'
import smtpConfig from '../../smtp/smtpConfig'

const useStyles = makeStyles({
    formMaxWidth: {
      maxWidth: 680,
    },
    textarea:{
        width:'100%',
        margin: '1rem 0'
    },
    controlerMargin:{
        margin: '.5rem 0',
        width:'100%'
    },
    buttonMargin:{
        margin: '1rem 0'
    },
    formMessage:{
        maxWidth: 680,
        margin: '1rem auto'
    },
    textAligned :{
        display:'flex',
        alignItems:'center',
        flexDirection:'row',
    },
    loadingContainer:{
        maxWidth: 680,
        display: 'flex',
        justifyContent: 'center',
        margin: '5rem auto',
    }
  },{index: 1});

const ContactForm = () => {

    const [ email, setEmail ] = React.useState('')
    const [ subject, setSubject ] = React.useState('')
    const [ name, setName ] = React.useState('')
    const [ message, setMessage ] = React.useState('Enter your message here')
    const [ emptyMessage, setEmptyMessage ] = React.useState(false)
    const [ messageState , setMessageState ] = React.useState('fill-form') //fill-form

    const classes = useStyles()


    const submit = async(e) =>{
        e.preventDefault()
        if(!message.trim()){
            setEmptyMessage(true)
            return
        }
        setEmptyMessage(false)
        setMessageState('message-loading')

        /*emailjs.sendForm('service_asidake', 'template_rdhvu5m', e.target, 'user_mFQZLcr8CBsV4OYRotWrG') //user_mFQZLcr8CBsV4OYRotWrG
        .then((result) => {
            //console.log(result.text);
            setMessageState('message-sent')
        }, (error) => {
            //console.log(error.text);
            setMessageState('sending-error')
        });*/
        try {
            const { data } = await axios.post(`${smtpConfig.host}/${smtpConfig.auth}`, {
                identifier: smtpConfig.user,
                password: smtpConfig.pass,
            })
            console.log(data.jwt);
            
            const response = await axios.post(`${smtpConfig.host}/${smtpConfig.action}`, {
                to: 'gerasordenador@gmail.com',
                name: name,
                subject: subject,
                from: email,
                message: message,
                },
                {
                headers: {
                    'Authorization': `Bearer ${data.jwt}`
                  },
                })
              console.log(response)
              response.data.activity ==="success" ?
              setMessageState('message-sent') : setMessageState('sending-error')

        } catch (error) {
            setMessageState('sending-error')
        }

        
        /*axios.post(`${smtpConfig.host}/${smtpConfig.action}`, {
            to: 'gerasordenador@gmail.com',
            name: name,
            subject: subject,
            from: email,
            message: message,
          })
          .then(function (response) {
            response.data.activity ==="success" ?
                setMessageState('message-sent') : setMessageState('sending-error')
          })
          .catch(function (error) {
            setMessageState('sending-error')
          });*/
    }

    return (
        <>
        {
            messageState ==='message-loading' ? (
                <Container className={ classes.loadingContainer }>
                    <CircularProgress disableShrink />
                </Container>
            ) : null
        }
        {
            messageState ==='sending-error' ? (
                <Container className={ classes.formMessage }>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        <p>Whoops<span role="img" aria-label="sad face">🙁</span>.Seems like something went wrong while trying to send your message.</p>
                        <span className={ classes.textAligned }>Please
                            <Button 
                                variant="text" 
                                color="primary"
                                onClick={() => { setMessageState('fill-form') }}
                            >
                                Try Again
                            </Button> 
                        </span>
                    </Alert>
                </Container>
            ) : null
            
        }
        {
            messageState ==='message-sent' ? (
                <Container className={ classes.formMessage }>
                    <Alert severity="success">
                        <AlertTitle>Message Sent</AlertTitle>
                        Thanks for your message! I'll get in touch with you very soon. 
                        <Link to="/"> 
                            <Button 
                                variant="text" 
                                color="primary"
                                component="span"
                            >
                                Go back to Homepage
                            </Button>
                        </Link>
                    </Alert>
                </Container>
            ) : null
            
        }
        { messageState ==='fill-form' ?
            (
                <Container className={classes.formMaxWidth}>
                        <ValidatorForm
                            onSubmit={submit}
                            onError={errors => console.log(errors)}
                        >
                        <Container>  
                            <FormGroup>
                                <TextValidator
                                        label="Enter your Name"
                                        onChange={e => setName(e.target.value)}
                                        name="user_name"
                                        value={name}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        className={classes.controlerMargin}
                                />
                                <TextValidator
                                        label="Enter your email address"
                                        onChange={e => setEmail(e.target.value)}
                                        name="user_email"
                                        value={email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['this field is required', 'email is not valid']}
                                        className={classes.controlerMargin}
                                />
                                <TextValidator
                                        label="Enter the subject of your message"
                                        onChange={e => setSubject(e.target.value)}
                                        name="user_subject"
                                        value={subject}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        className={classes.controlerMargin}
                                    />
                            </FormGroup>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                variant="outlined"
                                className = { classes.textarea }
                                onChange = {e => setMessage(e.target.value) }
                                value={message}
                                name="user_message"
                            />
                            { emptyMessage ?(
                                <FormHelperText error>Must type your message</FormHelperText>
                                ): null
                            }
                            <Button 
                                variant="contained" 
                                color="primary" 
                                disableElevation 
                                className={classes.buttonMargin}
                                type="submit"
                            >
                                    Send
                            </Button>   
                        </Container>
                    </ValidatorForm>
                </Container>
            ) :null 
        }
        </>
    )
}

export default ContactForm
