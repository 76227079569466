import React from 'react'
import { Box , Typography, Container, Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax'

const useStyles = makeStyles(theme =>({
    parallax:{
        height:'350px',
        [theme.breakpoints.down('md')]: {
            height:'400px'
        }
    },
    container:{
        position: 'relative',
        zIndex: '20',
        height: '100%',
    },
    title:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexFlow:'column',
        height:'100%',
        width:'100%',
        color:'#fff',
        textAlign:'center',
    },
    box:{
        background :'rgba(0,0,0,0.4)',
        width:'100%',
        height: '100%',
        zIndex:'10',
        position: 'relative'
    }
  }),{index: 1})

const Hero = (props) => {
    const classes = useStyles()
    
    return (
        <ParallaxProvider>
            <ParallaxBanner
                className={ classes.parallax }
                layers={[
                    {
                        image: props.data.strapiContact.HeroBanner.childImageSharp.fixed.src,
                        amount: 0.3,
                    }
                ]}
            >
            <Box className={classes.box}>
                <Container className={classes.container}>
                    <Hidden smDown>
                        <Typography 
                            variant="h2"
                            type="h1" 
                            className={classes.title}>
                                {props.data.strapiContact.Titulo}
                        </Typography>
                    </Hidden>
                    <Hidden mdUp>
                        <Typography 
                            variant="h3"
                            type="h1" 
                            className={classes.title}>
                                {props.data.strapiContact.Titulo}
                        </Typography>
                    </Hidden>
                </Container>
            </Box>    
        </ParallaxBanner>
     </ParallaxProvider>
    )
}

export default Hero
